import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const BanUserIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 20,
  ...props
}) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.07001 6.83594C7.33265 6.83594 7.59272 6.78421 7.83537 6.6837C8.07803 6.58319 8.2985 6.43587 8.48422 6.25015C8.66994 6.06443 8.81726 5.84396 8.91777 5.6013C9.01828 5.35865 9.07001 5.09858 9.07001 4.83594C9.07001 4.57329 9.01828 4.31322 8.91777 4.07057C8.81726 3.82792 8.66994 3.60744 8.48422 3.42172C8.2985 3.23601 8.07803 3.08869 7.83537 2.98818C7.59272 2.88767 7.33265 2.83594 7.07001 2.83594C6.53957 2.83594 6.03087 3.04665 5.65579 3.42172C5.28072 3.7968 5.07001 4.3055 5.07001 4.83594C5.07001 5.36637 5.28072 5.87508 5.65579 6.25015C6.03087 6.62522 6.53957 6.83594 7.07001 6.83594ZM6.65401 8.18527C6.34892 8.62613 6.12247 9.11648 5.98467 9.6346C5.41134 9.78127 4.91467 10.0453 4.50467 10.3979C4.03067 10.8079 3.73734 11.3093 3.73734 11.5026L3.73267 11.5806C3.71402 11.7505 3.6308 11.9068 3.5002 12.0172C3.3696 12.1275 3.2016 12.1834 3.03094 12.1734C2.86027 12.1634 2.69997 12.0882 2.58317 11.9633C2.46637 11.8385 2.40199 11.6736 2.40334 11.5026C2.40334 10.0666 4.28467 8.3666 6.65401 8.18527Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.263 14.1471C12.104 14.1471 13.5964 12.6548 13.5964 10.8138C13.5964 8.97285 12.104 7.48047 10.263 7.48047C8.42207 7.48047 6.92969 8.97285 6.92969 10.8138C6.92969 12.6548 8.42207 14.1471 10.263 14.1471ZM12.3987 8.80947C12.1104 8.50213 11.6275 8.48671 11.3201 8.77504L8.16161 11.7381C7.85427 12.0265 7.83886 12.5093 8.12718 12.8167C8.41551 13.124 8.89839 13.1394 9.20573 12.8511L12.3643 9.88802C12.6716 9.5997 12.687 9.11681 12.3987 8.80947Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
