import classnames from 'classnames';
import React, { forwardRef } from 'react';

import { useTheme } from '../../contexts/Theme';
import { pxToRem } from '../../lib/pxToRem';
import { CaretDownIcon, CheckIcon, SearchIcon } from '../Icons';
import { LoadingSpinner } from '../LoadingSpinner';
import { Text } from '../Text';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  infoText?: string;
  loading?: boolean;
  suffix?: string;
  valid?: boolean;
  variant?: 'default' | 'small';
}

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      error = false,
      infoText,
      loading = false,
      suffix,
      type = 'text',
      valid,
      variant = 'default',
      ...props
    },
    ref: React.MutableRefObject<HTMLInputElement>
  ) => {
    const { colors, fontFamilies, fontSizes, lineHeights } = useTheme();

    const classNames = classnames(className, {
      error,
      hasSuffix: !!suffix,
      valid,
    });

    // For iOS
    const isDateOrTime = type === 'date' || type === 'time';
    let fakePlaceholder = null;
    switch (type) {
      case 'date':
        fakePlaceholder = 'YYYY-MM-DD';
        break;
      case 'time':
        fakePlaceholder = 'HH:mm';
    }

    const iconSize = variant === 'small' ? 16 : 24;
    const iconVariant = variant === 'small' ? 'thick' : 'default';

    return (
      <div className="inputWrapper">
        <div
          className={classnames('group', {
            disabled: props.disabled,
            hasError: error,
            hasSuffix: suffix,
          })}
        >
          <div
            className={classnames('input', variant, {
              search: type === 'search',
            })}
          >
            {type === 'search' && (
              <span className="icon search">
                {loading ? (
                  <LoadingSpinner size={iconSize} />
                ) : (
                  <SearchIcon
                    color="var(--input-placeholder-color)"
                    size={iconSize}
                    variant={iconVariant}
                  />
                )}
              </span>
            )}
            <input ref={ref} className={classNames} type={type} {...props} />
            {isDateOrTime && (
              <>
                {!ref?.current?.value && (
                  <Text El="span" color="muted" className="ios placeholder">
                    {fakePlaceholder}
                  </Text>
                )}
                {!valid && isDateOrTime && (
                  <span className="ios icon">
                    <CaretDownIcon size={16} />
                  </span>
                )}
              </>
            )}
            {valid && (
              <span className="icon">
                <CheckIcon color={colors.accent} size={16} />
              </span>
            )}
            {infoText && (
              <Text El="span" className="infoText" color="muted">
                {infoText}
              </Text>
            )}
          </div>
          {suffix && <div className="suffix">{suffix}</div>}
        </div>
        <style jsx>{`
          .inputWrapper {
            width: 100%;
          }
          .group {
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            width: 100%;
          }
          .group.hasSuffix {
            border: 1px solid var(--input-border);
            box-shadow: 0 0 0 0 var(--focus-shadow);
            transition: border 200ms ease, box-shadow 200ms ease;
          }
          .group.hasSuffix:not(.disabled):hover,
          .group.hasSuffix:not(.disabled):focus-within {
            border: 1px solid var(--focus-border);
            box-shadow: 0 0 0 2px var(--focus-shadow);
          }
          .group.hasSuffix.hasError {
            border: 1px solid var(--input-error-border);
            box-shadow: 0 0 0 0 var(--input-error-shadow);
          }
          .group.hasSuffix.hasError:not(.disabled):hover,
          .group.hasSuffix.hasError:not(.disabled):focus-within {
            box-shadow: 0 0 0 2px var(--input-error-shadow);
          }
          .group.disabled {
            opacity: 0.6;
          }
          .input {
            position: relative;
            width: 100%;
          }
          .input.search input {
            padding-left: 32px;
          }
          input {
            background: var(--input-bg);
            border: 1px solid var(--input-border);
            border-radius: 8px;
            caret-color: var(--input-color);
            color: var(--input-color);
            display: block;
            font-family: ${fontFamilies.regular};
            font-size: ${fontSizes.base};
            font-weight: normal;
            height: 32px;
            line-height: ${lineHeights.base};
            padding: 5px 8px 7px;
            position: relative;
            transition: border 200ms ease, box-shadow 200ms ease;
            width: 100%;
            z-index: 2;
          }
          input.hasSuffix {
            border: none;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
          .suffix {
            background-color: var(--input-suffix-bg);
            border: 1px var(--input-border);
            border-radius: 0 8px 8px 0;
            color: var(--input-suffix-color);
            height: 32px;
            margin-left: -1px;
            margin-right: auto;
            padding: 6px 8px;
            white-space: nowrap;
          }
          input:not(.hasSuffix):not([disabled]):focus,
          input:not(.hasSuffix):not([disabled]):hover {
            border-color: var(--focus-border);
            box-shadow: 0 0 0 2px var(--focus-shadow);
          }
          input:not([disabled]):focus {
            outline: none;
          }
          input::placeholder {
            color: var(--input-placeholder-color);
          }
          input:-webkit-autofill {
            /* background doesn't work */
            /* see https://developer.mozilla.org/en-US/docs/Web/CSS/:-webkit-autofill */
            box-shadow: inset -1px 0 0 32px var(--input-bg),
              inset 0 -1px 0 32px var(--input-bg);
            color: var(--input-color);
            -webkit-text-fill-color: var(--input-color);
          }
          input[disabled] {
            background-color: var(--input-disabled-bg);
            color: var(--input-disabled-color);
          }
          input[disabled]::placeholder {
            color: var(--input-disabled-placeholder-color);
          }
          :global(form.submitted) input:not(:focus):invalid:not([disabled]),
          input.error:not([disabled]),
          input.error:not([disabled]):focus,
          input.error:not([disabled]):hover {
            border-color: var(--input-error-border);
            color: var(--input-error-color);
          }
          input.error:not(.hasSuffix):not([disabled]):focus,
          input.error:not(.hasSuffix):not([disabled]):hover {
            box-shadow: 0 0 0 2px var(--input-error-shadow);
          }
          .icon {
            display: inline-flex;
            pointer-events: none;
            position: absolute;
            right: 8px;
            top: 8px;
            z-index: 3;
          }
          .icon.search {
            left: 4px;
            right: auto;
            top: 4px;
          }
          .inputWrapper :global(.ios) {
            display: none;
          }
          input[type='search']::-webkit-search-cancel-button {
            display: none;
          }
          input[type='search']::-webkit-search-decoration {
            display: none;
          }
          .small input {
            height: 24px;
            padding: 3px 8px 5px;
          }
          .small.search input {
            padding-left: 24px;
          }
          .small + .suffix {
            height: 24px;
            padding: 3px 8px 5px;
          }
          .input :global(.infoText) {
            position: absolute;
            right: 8px;
            top: 7px;
            z-index: 5;
          }
          .small :global(.infoText) {
            top: 3px;
          }
          @supports (-webkit-touch-callout: none) {
            /* iOS specific style adjustments */
            .inputWrapper :global(.ios.placeholder) {
              /* Fake placeholder for date and time input */
              color: var(--input-placeholder-color);
              display: block;
              font-size: ${pxToRem(16)};
              left: 9px;
              pointer-events: none;
              position: absolute;
              top: 8px;
              z-index: 2;
            }
            .inputWrapper .ios.icon {
              /* Selector icon for date and time input */
              display: inline-flex;
              pointer-events: none;
              position: absolute;
              right: 8px;
              top: 8px;
              z-index: 3;
            }
            input {
              /* Font size of 16px to disable zoom on focus */
              font-size: ${pxToRem(16)};
              padding: 5px 8px;
            }
            input:not(.hasSuffix):not([disabled]):focus,
            input:not(.hasSuffix):not([disabled]):hover {
              // iOS ignores box-shadow, unless -webkit-appearance: none; is set
              // https://discourse.webflow.com/t/box-shadow-not-working-properly-in-ios/123553
              -webkit-appearance: none;
              box-shadow: 0 0 0 2px var(--focus-shadow);
            }
            input::placeholder {
              padding: 3px 0;
            }
            input[type='date'],
            input[type='time'] {
              -webkit-appearance: none;
              padding: 7px 8px;
            }
            input[type='search'] {
              -webkit-appearance: none;
              border-radius: 8px;
            }
            .small input {
              padding: 3px 8px;
            }
          }
          :global(.theme-dark)
            input[type='date']::-webkit-calendar-picker-indicator,
          :global(.theme-dark)
            input[type='time']::-webkit-calendar-picker-indicator {
            filter: invert(1);
          }
        `}</style>
      </div>
    );
  }
);
