import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
  variant?: 'thin' | 'thick';
}

export const ArrowRightIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  variant = 'thin',
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M4 8H12M12 8L9 5M12 8L9 11"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={variant === 'thick' ? 2 : 1}
    />
    <style jsx>{`
      svg {
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
