import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const HiddenIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6869 3.02006C13.8821 2.8248 13.8821 2.50821 13.6869 2.31295C13.4916 2.11769 13.175 2.11769 12.9798 2.31295L2.31311 12.9796C2.11785 13.1749 2.11785 13.4915 2.31311 13.6867C2.50838 13.882 2.82496 13.882 3.02022 13.6867L5.334 11.3729C6.13555 11.7511 7.04014 11.9998 8 11.9998C11.3333 11.9998 14 8.99984 14 7.99984C14 7.40271 13.0492 6.09244 11.5733 5.13363L13.6869 3.02006ZM9.72309 6.98385L6.98402 9.72293C7.28177 9.89887 7.6291 9.99984 8 9.99984C9.10457 9.99984 10 9.10441 10 7.99984C10 7.62893 9.89904 7.28161 9.72309 6.98385Z"
      fill={color}
    />
    <path
      d="M8 3.99984C8.55851 3.99984 9.09831 4.08406 9.60998 4.22898L7.83218 6.00678C6.86147 6.08742 6.08758 6.86131 6.00694 7.83202L3.59367 10.2453C2.60289 9.40297 2 8.47532 2 7.99984C2 6.99984 4.66667 3.99984 8 3.99984Z"
      fill={color}
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
