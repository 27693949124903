import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const CheckIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00015 12.0001C6.77415 12.0001 6.55415 11.9241 6.37515 11.7811L3.87515 9.78115C3.44415 9.43615 3.37415 8.80715 3.71915 8.37515C4.06415 7.94415 4.69515 7.87415 5.12515 8.21915L6.78815 9.55015L10.6781 3.93115C10.9921 3.47715 11.6131 3.36315 12.0691 3.67815C12.5231 3.99215 12.6371 4.61515 12.3222 5.06915L7.82215 11.5691C7.66515 11.7971 7.41915 11.9491 7.14515 11.9891C7.09715 11.9961 7.04815 12.0001 7.00015 12.0001Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
