import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const EmojiIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25Z"
      fill="none"
      stroke={color}
      strokeWidth="1.5"
    />
    <circle cx="9" cy="9.5" r="1.5" fill={color} />
    <circle cx="15" cy="9.5" r="1.5" fill={color} />
    <path
      d="M8 14C8 14 9 16.5 12 16.5C15 16.5 16 14 16 14"
      fill="none"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <style jsx>{`
      svg {
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
