import classnames from 'classnames';
import React, { Children } from 'react';

import { StackItem } from './StackItem';

interface Props extends React.HTMLAttributes<HTMLElement> {
  align?: 'start' | 'center' | 'end' | 'stretch';
  gap?: number;
  height?: string;
  horizontal?: boolean;
  justify?:
    | 'start'
    | 'center'
    | 'end'
    | 'stretch'
    | 'space-around'
    | 'space-between';
  padding?: string;
  spreadEvenly?: boolean;
  width?: string;
  wrap?: boolean;
}

export const Stack: React.FC<Props> = ({
  align = 'stretch',
  children,
  className,
  gap = 8,
  height = null,
  horizontal = false,
  justify = 'start',
  padding = '0',
  spreadEvenly = false,
  style = {},
  width = null,
  wrap = false,
  ...props
}) => {
  const alignMap = {
    center: 'center',
    end: 'flex-end',
    'space-around': 'space-around',
    'space-between': 'space-between',
    start: 'flex-start',
    stretch: 'stretch',
  };
  return (
    <div
      className={classnames('stack', className, {
        horizontal,
        vertical: !horizontal,
        wrap,
      })}
      style={{
        ...style,
        alignItems: alignMap[align],
        justifyContent: alignMap[justify],
        padding,
      }}
      {...props}
    >
      {Children.map(children, (child: JSX.Element, index) => {
        if (child?.type?.name === 'JSXStyle') {
          return child;
        }
        if (child?.type?.displayName === 'StackItem') {
          return (
            <StackItem
              {...child.props}
              className={classnames(child.props?.className, {
                fullWidth: !horizontal && align === 'stretch',
                horizontal,
                spreadEvenly,
                stretch: justify === 'stretch',
                wrap,
                vertical: !horizontal,
              })}
            />
          );
        }
        return React.Children.count(child) ? (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`stack${index}`}
            className={classnames('stackItem', {
              fullWidth: !horizontal && align === 'stretch',
              horizontal,
              spreadEvenly,
              stretch: justify === 'stretch',
              wrap,
              vertical: !horizontal,
            })}
          >
            {child}
          </div>
        ) : null;
      })}
      <style jsx>{`
        .stack {
          display: flex;
          flex-wrap: nowrap;
          ${height ? `height: ${height};` : ''}
          ${width ? `width: ${width};` : ''}
        }
        .stack.horizontal {
          flex-direction: row;
          margin-left: -${gap / 2}px;
          margin-right: -${gap / 2}px;
        }
        .stack.horizontal.wrap {
          margin-top: -${gap}px;
        }
        .stack.vertical {
          flex-direction: column;
        }
        .stack.wrap {
          flex-wrap: wrap;
        }
        .stack > :global(.stackItem) {
          min-width: 0;
        }
        .stack > :global(.stackItem.fullWidth) {
          width: 100%;
        }
        .stack > :global(.stackItem.horizontal:not(:empty)) {
          margin-left: ${gap / 2}px;
          margin-right: ${gap / 2}px;
        }
        .stack > :global(.stackItem.horizontal.wrap:not(:empty)) {
          margin-top: ${gap}px;
        }
        .stack
          > :global(.stackItem.vertical:not(:empty)
            ~ .stackItem.vertical:not(:empty)) {
          margin-top: ${gap}px;
        }
        .stack > :global(.stackItem.spreadEvenly) {
          flex: 1 1 0;
        }
        .stack > :global(.stackItem.stretch) {
          flex-grow: 1;
        }
        .stack > :global(.stackItem:empty) {
          display: none;
        }
      `}</style>
    </div>
  );
};
