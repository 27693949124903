import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const ReportUserIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.139 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-.624 2.024a6.967 6.967 0 0 0-1.004 2.174c-.86.22-1.605.616-2.22 1.145C5.58 15.958 5.14 16.71 5.14 17l-.007.117A1 1 0 0 1 3.139 17c0-2.154 2.822-4.704 6.376-4.976ZM9.861 16a5 5 0 1 0 10 0 5 5 0 0 0-10 0Zm5.994-2.5a1 1 0 0 0-1.994.117V15.5l.007.116a1 1 0 0 0 1.993-.116v-1.883l-.006-.117Zm-.994 5.722a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
