import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const LandscapeIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 24" {...props}>
    <path
      d="M14.976 9.15507L8.93629 17.7832C8.35636 18.6116 8.94905 19.75 9.96033 19.75H22.0397C23.051 19.75 23.6436 18.6116 23.0637 17.7832L17.024 9.15507C16.5264 8.44417 15.4736 8.44417 14.976 9.15507Z"
      fill="white"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M7.03972 12.9808L3.10724 17.6998C2.42878 18.5139 3.00772 19.75 4.06752 19.75H11.9325C12.9923 19.75 13.5712 18.5139 12.8928 17.6998L8.96028 12.9808C8.46053 12.3811 7.53947 12.3811 7.03972 12.9808L7.61589 13.4609L7.03972 12.9808Z"
      fill="white"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <circle
      cx="8"
      cy="6"
      r="2.25"
      fill="white"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
