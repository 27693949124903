import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
  active?: boolean;
}

export const ScreenshareIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 44,
  active = false,
  ...props
}) => (
  <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" {...props}>
    {!active ? (
      <path d="M17.99 23.616h1.637v-2.531c0-.45.148-.827.437-1.125.289-.299.654-.45 1.09-.45h2.21v1.968l2.727-2.812-2.727-2.813v1.97h-2.21c-.872 0-1.62.32-2.236.955a3.197 3.197 0 0 0-.927 2.307v2.531Zm-7.172 7.51a.802.802 0 0 1-.589-.237c-.158-.157-.229-.36-.229-.607 0-.248.076-.445.23-.608.152-.163.348-.236.588-.236h22.364c.234 0 .43.079.589.236.158.158.229.36.229.608a.846.846 0 0 1-.23.607c-.152.163-.348.237-.588.237H10.818Zm1.91-3.376c-.437 0-.819-.168-1.146-.506a1.644 1.644 0 0 1-.491-1.181v-13.5c0-.45.164-.844.49-1.182.328-.337.71-.506 1.146-.506h18.546c.436 0 .818.169 1.145.506.328.338.491.732.491 1.182v13.5c0 .45-.163.844-.49 1.181-.328.338-.71.506-1.146.506H12.727Zm0-1.687h18.545v-13.5H12.727v13.5Z" />
    ) : (
      <path d="m24.717 21.066-3.057-3.057h2.434v-1.98l2.83 2.83-2.207 2.207Zm7.16 7.189-1.698-1.698h1.953v-13.67H16.51l-1.698-1.698h17.321c.453 0 .85.17 1.189.51.34.339.51.735.51 1.188v13.67c0 .492-.188.905-.567 1.245-.38.34-.838.492-1.387.453ZM32.783 34l-2.745-2.745H10.226v-1.698H28.34l-1.302-1.302H13.255c-.453 0-.85-.17-1.189-.51-.34-.34-.51-.736-.51-1.188V12.774L10 11.217 11.217 10 34 32.783 32.783 34ZM20.217 21.434v2.406h-1.698v-2.547c0-.187.017-.402.057-.634.04-.238.101-.459.198-.663l-5.52-5.524v12.085H25.34l-5.123-5.123Z" />
    )}
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
