import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const ChatIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.995 7.824A3 3 0 0 0 17 5H7l-.176.005A3 3 0 0 0 4 8v6l.005.176A3 3 0 0 0 7 17h1v2l.006.11a1 1 0 0 0 1.594.69l3.733-2.8H17l.176-.005A3 3 0 0 0 20 14v-1l-.007-.117A1 1 0 0 0 18 13v1l-.007.117A1 1 0 0 1 17 15h-4l-.129.008a1 1 0 0 0-.471.192L10 17v-1l-.007-.117A1 1 0 0 0 9 15H7l-.117-.007A1 1 0 0 1 6 14V8l.007-.117A1 1 0 0 1 7 7h10l.117.007A1 1 0 0 1 18 8v1l.007.117A1 1 0 0 0 20 9V8l-.005-.176ZM9 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
