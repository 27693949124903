import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const GaugeIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.864 16.1422C13.6924 17.3137 11.793 17.3137 10.6214 16.1422C9.44981 14.9706 9.44981 13.0711 10.6214 11.8995C11.793 10.7279 22.6422 4.12134 22.6422 4.12134C22.6422 4.12134 21.2642 6.38431 19.6552 8.95481C20.6598 10.3761 21.25 12.1103 21.25 13.9824C21.25 16.0617 20.5209 17.9729 19.3047 19.4724C19.0438 19.7941 18.5715 19.8434 18.2498 19.5825C17.9281 19.3216 17.8788 18.8493 18.1397 18.5276C19.1472 17.2853 19.75 15.705 19.75 13.9824C19.75 12.661 19.3947 11.4222 18.7741 10.356C17.1022 13.0008 15.4046 15.6016 14.864 16.1422ZM12.5 5.25002C13.7098 5.25002 14.8624 5.49511 15.9106 5.93831C15.3676 6.28008 14.8189 6.62746 14.2821 6.97009C13.712 6.82637 13.115 6.75002 12.5 6.75002C8.49437 6.75002 5.25 9.98961 5.25 13.9824C5.25 15.705 5.85277 17.2853 6.86029 18.5276C7.1212 18.8493 7.07191 19.3216 6.75019 19.5825C6.42848 19.8434 5.95617 19.7941 5.69526 19.4724C4.47914 17.9729 3.75 16.0617 3.75 13.9824C3.75 9.15806 7.66907 5.25002 12.5 5.25002Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
