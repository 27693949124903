import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const UserIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 7C8 5 9 3 12 3C15 3 16 5 16 7C16 10 14.2091 12 12 12C9.79086 12 8 10 8 7ZM4.5 20C4.5 16.3771 5.73052 14.3949 8.93456 12.5673C9.85701 13.1654 10.9186 13.5 12 13.5C13.0818 13.5 14.1439 13.1651 15.0666 12.5666C18.2695 14.3853 19.4984 16.3218 19.4984 19.9443C19.4995 19.9628 19.5 19.9814 19.5 20C19.5 20.5215 19.0772 20.9443 18.5557 20.9443L18.4984 20.9443L5.5 21C4.94772 21 4.5 20.5523 4.5 20Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
