import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const EllipsisVerticalIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 20,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8C13.1046 8 14 7.10457 14 6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6C10 7.10457 10.8954 8 12 8ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14ZM14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
