import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const PinIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.666 5.912c-.15.264-.331.51-.537.735v4.156c1.292.716 2.292 1.863 2.787 3.236.374 1.04-.554 1.961-1.659 1.961H7.743c-1.105 0-2.033-.922-1.659-1.96.495-1.374 1.495-2.52 2.787-3.237V6.645a4.013 4.013 0 0 1-.535-.733C7.79 4.952 8.725 4 9.829 4h4.343c1.105 0 2.04.952 1.494 1.912ZM11 17h2v2.062L11.007 21a.957.957 0 0 1-.007-.112V17Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);

// export const PinIcon: React.FC<Props> = ({
//   color = 'currentColor',
//   size = 24,
//   ...props
// }) => (
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
//     <path d="M5.82577 4.55164C5.59118 3.80267 6.13655 3 6.95175 3H9.71482C10.53 3 11.0754 3.80267 10.8408 4.55164C10.7544 4.82751 10.667 5.12695 10.6017 5.39691C10.5335 5.67915 10.4999 5.88769 10.4999 6C10.4999 6.0452 10.5233 6.19737 10.5914 6.47462C10.6545 6.73169 10.744 7.04987 10.8473 7.39579C11.0537 8.0867 11.3095 8.86856 11.5056 9.45407C11.7588 10.2102 11.1987 11 10.3956 11H8.83328V13.3333C8.83328 13.6095 8.60942 13.8333 8.33328 13.8333C8.05714 13.8333 7.83328 13.6095 7.83328 13.3333V11H6.27101C5.46786 11 4.90777 10.2102 5.16095 9.45407C5.35701 8.86856 5.61288 8.0867 5.81926 7.39579C5.92259 7.04987 6.01203 6.73169 6.07519 6.47462C6.1433 6.19737 6.16661 6.0452 6.16661 6C6.16661 5.88769 6.13308 5.67915 6.06483 5.39691C5.99955 5.12695 5.91218 4.82751 5.82577 4.55164Z" />
//     <style jsx>{`
//       svg {
//         fill: ${color};
//         height: ${size}px;
//         width: ${size}px;
//         display: block;
//       }
//     `}</style>
//   </svg>
// );
