import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const AddEmojiIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99967 2.35889C4.88422 2.35889 2.35865 4.88447 2.35865 7.99992C2.35865 11.1154 4.88422 13.6409 7.99967 13.6409C11.1151 13.6409 13.6407 11.1154 13.6407 7.99992C13.6407 7.7167 13.8703 7.4871 14.1535 7.4871C14.4367 7.4871 14.6663 7.7167 14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C8.2829 1.33325 8.5125 1.56285 8.5125 1.84607C8.5125 2.1293 8.2829 2.35889 7.99967 2.35889Z"
      fill={color}
    />
    <circle cx="6" cy="6.33325" r="1" fill={color} />
    <circle cx="10" cy="6.33325" r="1" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.79703 9.14683L5.79674 9.14611L5.79633 9.1451L5.79877 9.15073C5.80175 9.1575 5.80726 9.16969 5.81545 9.18655C5.83186 9.22033 5.85878 9.27235 5.8973 9.33655C5.97477 9.46566 6.09647 9.6396 6.27003 9.81316C6.61094 10.1541 7.15589 10.5001 7.99981 10.5001C8.84372 10.5001 9.38867 10.1541 9.72959 9.81316C9.90315 9.6396 10.0248 9.46566 10.1023 9.33655C10.1408 9.27235 10.1678 9.22033 10.1842 9.18655C10.1924 9.16969 10.1979 9.1575 10.2008 9.15073L10.2034 9.14492C10.3069 8.8904 10.5967 8.76696 10.8522 8.86915C11.1086 8.9717 11.2333 9.26269 11.1307 9.51908L10.6665 9.33338C11.1307 9.51908 11.1308 9.51883 11.1307 9.51908L11.1298 9.52127L11.1287 9.52408L11.1256 9.53153C11.1232 9.53729 11.12 9.54464 11.1162 9.55347C11.1084 9.57112 11.0976 9.59473 11.0837 9.62345C11.0558 9.68081 11.015 9.759 10.9598 9.85105C10.8498 10.0344 10.6798 10.2772 10.4367 10.5203C9.94428 11.0127 9.15589 11.5001 7.99981 11.5001C6.84372 11.5001 6.05534 11.0127 5.56292 10.5203C5.31982 10.2772 5.14985 10.0344 5.03981 9.85105C4.98458 9.759 4.9438 9.68081 4.91594 9.62345C4.90199 9.59473 4.89122 9.57112 4.88346 9.55347C4.87957 9.54464 4.87643 9.53729 4.87402 9.53153L4.87093 9.52408L4.86978 9.52127L4.86931 9.5201C4.86921 9.51985 4.8689 9.51908 5.33314 9.33338L4.8689 9.51908C4.76635 9.26269 4.89105 8.9717 5.14745 8.86915C5.40291 8.76696 5.69279 8.89059 5.79633 9.1451"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.867 0.533333C13.867 0.238781 13.6282 0 13.3337 0C13.0391 0 12.8003 0.238781 12.8003 0.533333V2.13333H11.2003C10.9058 2.13333 10.667 2.37211 10.667 2.66667C10.667 2.96122 10.9058 3.2 11.2003 3.2H12.8003V4.8C12.8003 5.09455 13.0391 5.33333 13.3337 5.33333C13.6282 5.33333 13.867 5.09455 13.867 4.8V3.2H15.467C15.7615 3.2 16.0003 2.96122 16.0003 2.66667C16.0003 2.37211 15.7615 2.13333 15.467 2.13333H13.867V0.533333Z"
      fill={color}
    />
    <style jsx>{`
      svg {
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
