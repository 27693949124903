import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const DownloadIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00016 3.33203C7.63203 3.33203 7.33359 3.63047 7.33359 3.9986V8.22931L6.26805 7.11759C6.03124 6.87051 5.63631 6.87046 5.39943 7.11747C5.17625 7.35018 5.1762 7.71746 5.39931 7.95025L7.27831 9.91068C7.29621 9.92936 7.31465 9.94719 7.33359 9.96416V9.9987H7.37423C7.73868 10.2921 8.26182 10.2921 8.62627 9.9987H8.66673V9.96432C8.68574 9.94729 8.70426 9.9294 8.72222 9.91065L10.6012 7.95014C10.8242 7.7174 10.8242 7.35023 10.6011 7.11754C10.3642 6.8705 9.96931 6.87053 9.7325 7.1176L8.66673 8.22956V3.9986C8.66673 3.63046 8.3683 3.33203 8.00016 3.33203ZM3.99995 11.3297C3.63188 11.3297 3.3335 11.6281 3.3335 11.9961C3.3335 12.3642 3.63188 12.6626 3.99995 12.6626H11.9987C12.3668 12.6626 12.6651 12.3642 12.6651 11.9961C12.6651 11.6281 12.3668 11.3297 11.9987 11.3297H3.99995Z"
      fill={color}
    />
    <style jsx>{`
      svg {
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
