import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const KickUserIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 20,
  ...props
}) => (
  <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.06903 6.83203C7.33167 6.83203 7.59175 6.7803 7.8344 6.67979C8.07705 6.57928 8.29753 6.43196 8.48324 6.24625C8.66896 6.06053 8.81628 5.84005 8.91679 5.5974C9.0173 5.35475 9.06903 5.09468 9.06903 4.83203C9.06903 4.56939 9.0173 4.30932 8.91679 4.06666C8.81628 3.82401 8.66896 3.60353 8.48324 3.41782C8.29753 3.2321 8.07705 3.08478 7.8344 2.98427C7.59175 2.88376 7.33167 2.83203 7.06903 2.83203C6.5386 2.83203 6.02989 3.04275 5.65482 3.41782C5.27974 3.79289 5.06903 4.3016 5.06903 4.83203C5.06903 5.36246 5.27974 5.87117 5.65482 6.24625C6.02989 6.62132 6.5386 6.83203 7.06903 6.83203ZM6.65303 8.18136C6.34794 8.62222 6.12149 9.11258 5.9837 9.6307C5.41036 9.77736 4.9137 10.0414 4.5037 10.394C4.0297 10.804 3.73636 11.3054 3.73636 11.4987L3.7317 11.5767C3.71305 11.7466 3.62982 11.9029 3.49922 12.0132C3.36862 12.1236 3.20063 12.1795 3.02996 12.1695C2.85929 12.1595 2.69899 12.0843 2.58219 11.9594C2.46539 11.8346 2.40101 11.6697 2.40236 11.4987C2.40236 10.0627 4.2837 8.3627 6.65303 8.18136Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.263 14.2135C12.104 14.2135 13.5964 12.7212 13.5964 10.8802C13.5964 9.03926 12.104 7.54688 10.263 7.54688C8.42207 7.54688 6.92969 9.03926 6.92969 10.8802C6.92969 12.7212 8.42207 14.2135 10.263 14.2135ZM8.26278 10.8795C8.26278 11.2477 8.56125 11.5461 8.92944 11.5461H11.5961C11.9643 11.5461 12.2628 11.2477 12.2628 10.8795C12.2628 10.5113 11.9643 10.2128 11.5961 10.2128L8.92944 10.2128C8.56125 10.2128 8.26278 10.5113 8.26278 10.8795Z"
    />

    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
