import classnames from 'classnames';
import React from 'react';
import css from 'styled-jsx/css';

import { useTheme } from '../../contexts/Theme/useTheme';
import { pxToRem } from '../../lib/pxToRem';

interface BaseProps {
  color?:
    | 'default'
    | 'muted'
    | 'warning'
    | 'error'
    | 'info'
    | 'success'
    | 'white'
    | 'inverse'
    | 'inherit';
  El?:
    | 'p'
    | 'span'
    | 'code'
    | 'small'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'strong'
    | 'div'
    | 'label'
    | 'dt'
    | 'dd'
    | 'ol'
    | 'ul'
    | 'li';
  textAlign?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
  truncate?: boolean;
  underline?: boolean;
  variant?:
    | 'xsmall'
    | 'small'
    | 'mono'
    | 'base'
    | 'strong'
    | 'large'
    | 'largestrong'
    | 'm-heading-big'
    | 'm-heading-medium'
    | 'm-heading-small'
    | 'm-base'
    | 'm-strong'
    | 'm-small'
    | 'xlarge';
}

type TextProps = BaseProps & React.HTMLAttributes<HTMLElement>;
type LabelProps = BaseProps &
  React.LabelHTMLAttributes<HTMLLabelElement> & {
    El: 'label';
  };

type Props = TextProps | LabelProps;

const useTextStyles = () => css.resolve`
  .underline {
    text-decoration: underline;
  }
  .default {
    color: var(--text-default);
    text-decoration-color: var(--text-default);
  }
  .muted {
    color: var(--text-muted);
    text-decoration-color: var(--text-muted);
  }
  .error {
    color: var(--text-error);
    text-decoration-color: var(--text-error);
  }
  .info {
    color: var(--text-info);
    text-decoration-color: var(--text-info);
  }
  .success {
    color: var(--text-success);
    text-decoration-color: var(--text-success);
  }
  .warning {
    color: var(--text-warning);
    text-decoration-color: var(--text-warning);
  }
  .inverse {
    color: var(--text-inverse);
    text-decoration-color: var(--text-inverse);
  }
  .white {
    color: var(--text-white);
    text-decoration-color: var(--text-white);
  }
  .inherit {
    color: inherit;
    text-decoration-color: inherit;
  }
  .truncate {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const XSmall: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'small',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies, fontSizes, lineHeights } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.medium};
          font-size: ${fontSizes.xsmall};
          font-weight: normal;
          letter-spacing: 0.4px;
          line-height: ${lineHeights.small};
          text-align: ${textAlign};
          text-transform: uppercase;
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const Small: React.FC<TextProps> = ({
  children,
  className,
  color = 'muted',
  El = 'p',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies, fontSizes, lineHeights } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${El === 'strong'
            ? fontFamilies.medium
            : fontFamilies.regular};
          font-size: ${fontSizes.small};
          font-weight: normal;
          line-height: ${lineHeights.base};
          margin: 0;
          text-align: ${textAlign};
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const Mono: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'code',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies, fontSizes, lineHeights } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.mono};
          font-size: ${fontSizes.mono};
          line-height: ${lineHeights.base};
          text-align: ${textAlign};
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const Strong: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'strong',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies, fontSizes, lineHeights } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.medium};
          font-size: ${fontSizes.base};
          font-weight: normal;
          line-height: ${lineHeights.base};
          text-align: ${textAlign};
          margin: 0;
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const BaseText: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  dangerouslySetInnerHTML,
  El = 'p',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies, fontSizes, lineHeights } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {dangerouslySetInnerHTML ? (
        <span dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
      ) : (
        children
      )}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.regular};
          font-size: ${fontSizes.base};
          font-weight: normal;
          line-height: ${lineHeights.base};
          margin: 0;
          text-align: ${textAlign};
        }
        ${El} :global(> strong) {
          font-family: ${fontFamilies.medium};
          font-size: ${fontSizes.base};
          font-weight: normal;
          line-height: ${lineHeights.base};
          margin: 0;
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const Large: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'h2',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies, fontSizes, lineHeights } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${El === 'strong'
            ? fontFamilies.medium
            : fontFamilies.regular};
          font-size: ${fontSizes.large};
          font-weight: normal;
          line-height: ${lineHeights.large};
          margin: 0;
          text-align: ${textAlign};
        }
        ${El} :global(> strong) {
          font-family: ${fontFamilies.medium};
          font-size: ${fontSizes.large};
          font-weight: normal;
          line-height: ${lineHeights.large};
          margin: 0;
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const LargeStrong: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'h2',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies, fontSizes, lineHeights } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.medium};
          font-size: ${fontSizes.large};
          font-weight: normal;
          line-height: ${lineHeights.large};
          margin: 0;
          text-align: ${textAlign};
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const XLarge: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'h1',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies, fontSizes, lineHeights } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.medium};
          font-size: ${fontSizes.xlarge};
          font-weight: normal;
          line-height: ${lineHeights.xlarge};
          margin: 0;
          text-align: ${textAlign};
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const MarketingHeadingBig: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'h1',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.semibold};
          font-size: ${pxToRem(32)};
          font-weight: normal;
          line-height: ${pxToRem(36)};
          margin: 0;
          text-align: ${textAlign};
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const MarketingHeadingMedium: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'h2',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.semibold};
          font-size: ${pxToRem(24)};
          font-weight: normal;
          line-height: ${pxToRem(28)};
          margin: 0;
          text-align: ${textAlign};
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const MarketingHeadingSmall: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'h3',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.semibold};
          font-size: ${pxToRem(16)};
          font-weight: normal;
          line-height: ${pxToRem(20)};
          margin: 0;
          text-align: ${textAlign};
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const MarketingBase: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'p',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.regular};
          font-size: ${pxToRem(16)};
          line-height: ${pxToRem(28)};
          margin: 0;
          text-align: ${textAlign};
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const MarketingStrong: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'strong',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.semibold};
          font-size: ${pxToRem(16)};
          line-height: ${pxToRem(28)};
          margin: 0;
          text-align: ${textAlign};
        }
      `}</style>
      {text.styles}
    </El>
  );
};

const MarketingSmall: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  El = 'small',
  textAlign = 'inherit',
  truncate,
  underline,
  ...props
}) => {
  const { fontFamilies } = useTheme();
  const text = useTextStyles();
  return (
    <El
      className={classnames(className, color, text.className, {
        truncate,
        underline,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        ${El} {
          font-family: ${fontFamilies.regular};
          font-size: ${pxToRem(12)};
          font-weight: 400;
          line-height: ${pxToRem(16)};
          margin: 0;
          text-align: ${textAlign};
        }
      `}</style>
      {text.styles}
    </El>
  );
};

export const Text: React.FC<Props> = ({ variant = 'base', ...props }) => {
  switch (variant) {
    case 'base':
      return <BaseText {...props} />;
    case 'mono':
      return <Mono {...props} />;
    case 'xsmall':
      return <XSmall {...props} />;
    case 'small':
      return <Small {...props} />;
    case 'strong':
      return <Strong {...props} />;
    case 'large':
      return <Large {...props} />;
    case 'largestrong':
      return <LargeStrong {...props} />;
    case 'xlarge':
      return <XLarge {...props} />;
    case 'm-heading-big':
      return <MarketingHeadingBig {...props} />;
    case 'm-heading-medium':
      return <MarketingHeadingMedium {...props} />;
    case 'm-heading-small':
      return <MarketingHeadingSmall {...props} />;
    case 'm-base':
      return <MarketingBase {...props} />;
    case 'm-strong':
      return <MarketingStrong {...props} />;
    case 'm-small':
      return <MarketingSmall {...props} />;
    default:
      return <BaseText {...props} />;
  }
};
