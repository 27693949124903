import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const HomeIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.9999 6.35675L13.05 7.07868C13.2775 7.23512 13.5888 7.17747 13.7452 6.94992C13.9017 6.72237 13.844 6.41108 13.6165 6.25464L8.47199 2.7178C8.18761 2.52229 7.81215 2.52229 7.52778 2.7178L2.38329 6.25464C2.15573 6.41108 2.09809 6.72237 2.25453 6.94992C2.41097 7.17747 2.72226 7.23512 2.94981 7.07868L3.99988 6.35676V12.3333C3.99988 12.7015 4.29835 13 4.66654 13H11.3332C11.7014 13 11.9999 12.7015 11.9999 12.3333V6.35675Z"
      fill={color}
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
