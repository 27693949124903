import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const GridViewIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M6 8.5C6 7.94772 6.44772 7.5 7 7.5H17C17.5523 7.5 18 7.94772 18 8.5V15.5C18 16.0523 17.5523 16.5 17 16.5H7C6.44772 16.5 6 16.0523 6 15.5V8.5Z"
      stroke={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 16V14H6.5V13H9.5V11H6.5V10H9.5V8H10.5V10H13.5V8H14.5V10H17.5V11H14.5V13H17.5V14H14.5V16H13.5V14H10.5V16H9.5ZM13.5 13V11H10.5V13H13.5Z"
      fill={color}
    />
    <style jsx>{`
      svg {
        fill: transparent;
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
