import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  rotate?: number;
  size?: number;
  variant?: 'default' | 'thick';
}

export const CloseIcon: React.FC<Props> = ({
  color = 'currentColor',
  rotate = 0,
  size = 24,
  variant = 'default',
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g>
      <line
        x1="6.41"
        y1="6.41"
        x2="17.59"
        y2="17.59"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={variant === 'thick' ? 2 : 1.5}
      />
      <line
        x1="17.59"
        y1="6.41"
        x2="6.41"
        y2="17.59"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={variant === 'thick' ? 2 : 1.5}
      />
    </g>
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
      g {
        transform: rotate(${rotate}deg);
        transform-origin: center;
      }
    `}</style>
  </svg>
);
