import { DailyCustomTrayButtons } from '@daily-co/daily-js';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';

/**
 * General UI state.
 * These are general purpose shared UI states.
 */

const pinnedIdState = atom<string | null>({
  key: 'pinned-id',
  default: null,
});
/**
 * Returns the currently pinned session or screen id and a setter to change it.
 */
export const usePinnedId = () => useRecoilState(pinnedIdState);

export type PreferredViewMode = 'grid' | 'speaker';

const preferredViewModeState = atom<PreferredViewMode>({
  key: 'preferred-view-mode',
  default: new Promise((resolve) => {
    if (typeof window === 'undefined') {
      resolve('speaker');
      return;
    }
    const search = new URLSearchParams(window.location.search);
    if (search.get('v') === 'grid') {
      resolve('grid');
      return;
    }
    resolve('grid');
    return;
  }),
});
/**
 * Returns the currently preferred view mode and a setter to change it.
 */
export const usePreferredViewMode = () =>
  useRecoilState(preferredViewModeState);

type ViewMode = PreferredViewMode | 'mobile';

const viewModeSelector = selector<ViewMode>({
  key: 'view-mode',
  get: ({ get }) => {
    const isMobile = get(isMobileState);
    if (isMobile) return 'mobile';
    const pinnedId = get(pinnedIdState);
    if (pinnedId) return 'speaker';
    const preferredViewMode = get(preferredViewModeState);
    return preferredViewMode;
  },
});
/**
 * Returns the current view mode: 'grid', 'speaker' or 'mobile'.
 * In case a participant or screen is pinned or a screen is shared, it will default to 'speaker'.
 */
export const useViewMode = () => {
  const viewMode = useRecoilValue(viewModeSelector);
  return viewMode;
};

export type SettingsView =
  | 'devices'
  | 'quality'
  | 'language'
  | 'effects'
  | 'version';
const settingsViewState = atom<SettingsView | null>({
  key: 'settings-view',
  default: null,
});
/**
 * Used to control visibility and tabs for SettingsModal.
 */
export const useSettingsView = () => useRecoilState(settingsViewState);

export type SidebarView = 'people' | 'chat' | 'network' | 'admins';
const sidebarViewState = atom<SidebarView | null>({
  key: 'sidebar-view',
  default: null,
});
/**
 * Used to control visibility and tabs for Sidebar.
 */
export const useSidebarView = () => useRecoilState(sidebarViewState);

export type RecordingUIState =
  | 'starting3'
  | 'starting2'
  | 'starting1'
  | 'saved'
  | null;
const recordingUIState = atom<RecordingUIState>({
  key: 'recording-ui',
  default: null,
});
/**
 * Holds additional recording UI states for countdown.
 */
export const useRecordingUI = () => useRecoilState(recordingUIState);

/**
 * Mobile UI state.
 * In case the user browses with a mobile device, this state enforces mobile UI
 * and overall mobile adjustments (like increased font-size and icons).
 */

const isMobileState = atom<boolean>({
  key: 'is-mobile',
  default: false,
});
/**
 * Returns wether or not this call is in mobile mode and a setter to change it.
 */
export const useIsMobile = () => useRecoilState(isMobileState);

const mobileTrayState = atom<boolean>({
  key: 'mobile-tray',
  default: true,
});
/**
 * Returns wether or not the mobile tray should be shown and a setter to change it.
 */
export const useMobileTray = () => useRecoilState(mobileTrayState);

/**
 * Call configuration states.
 */

const maxGridTilesPerPageState = atom<number>({
  key: 'max-grid-tiles-per-page',
  default: 25,
});
/**
 * Returns the maximum amount of tiles per page in grid view and a setter to change it.
 */
export const useMaxGridTilesPerPage = () =>
  useRecoilState(maxGridTilesPerPageState);

const minGridTilesPerPageState = atom<number>({
  key: 'min-grid-tiles-per-page',
  default: 1,
});
/**
 * Returns the minimum amount of tiles per page in grid view and a setter to change it.
 */
export const useMinGridTilesPerPage = () =>
  useRecoilState(minGridTilesPerPageState);

const showLocalVideoState = atom<boolean>({
  key: 'show-local-video',
  default: true,
});
/**
 * Returns a flag indicating wether the call is configured to show the local user's view
 * and a setter to change it.
 */
export const useShowLocalVideo = () => useRecoilState(showLocalVideoState);

const customTrayButtonsState = atom<DailyCustomTrayButtons>({
  key: 'custom-tray-buttons',
  default: {},
});
/**
 * Returns Array of custom tray buttons and a setter to change it.
 */
export const useCustomTrayButtons = () =>
  useRecoilState(customTrayButtonsState);

/**
 * Modal visibility states.
 * There are a couple of single-purpose modals whose visibility is controlled
 * via shared UI state.
 */

const autoplayFailedModalState = atom<boolean>({
  key: 'autoplay-failed-modal',
  default: false,
});
/**
 * Used to control visibility of AutoPlayFailedModal.
 */
export const useAutoplayFailedModal = () =>
  useRecoilState(autoplayFailedModalState);

const deviceInUseModalState = atom<boolean>({
  key: 'device-in-use-modal',
  default: false,
});
/**
 * Used to control visibility of DeviceInUseModal.
 */
export const useDeviceInUseModal = () => useRecoilState(deviceInUseModalState);

const deviceNotFoundModalState = atom<boolean>({
  key: 'device-not-found-modal',
  default: false,
});
/**
 * Used to control visibility of DeviceNotFoundModal.
 */
export const useDeviceNotFoundModal = () =>
  useRecoilState(deviceNotFoundModalState);

const nameModalState = atom<boolean>({
  key: 'name-modal-state',
  default: false,
});
/**
 * Used to control visibility of NameModal.
 */
export const useNameModal = () => useRecoilState(nameModalState);

const recordingErrorModalState = atom<boolean>({
  key: 'recording-error-modal-state',
  default: false,
});
/**
 * Used to control visibility of RecordingErrorModal.
 */
export const useRecordingErrorModal = () =>
  useRecoilState(recordingErrorModalState);

const startRecordingModalState = atom<boolean>({
  key: 'start-recording-modal-state',
  default: false,
});
/**
 * Used to control visibility of StartRecordingModal.
 */
export const useStartRecordingModal = () =>
  useRecoilState(startRecordingModalState);

const startScreenshareModalState = atom<boolean>({
  key: 'start-screenshare-modal-state',
  default: false,
});
/**
 * Used to control visibility of StartScreenshareModal.
 */
export const useStartScreenshareModal = () =>
  useRecoilState(startScreenshareModalState);

type ScreenshareError = 'blocked-by-os' | 'blocked-by-browser';

const screenshareErrorModalState = atom<ScreenshareError | null>({
  key: 'screenshare-error-modal-state',
  default: null,
});
/**
 * Used to control visibility of ScreenshareErrorModal.
 */
export const useScreenshareErrorModal = () =>
  useRecoilState(screenshareErrorModalState);

const unblockPermissionsModalState = atom<boolean>({
  key: 'unblock-modal-state',
  default: false,
});
/**
 * Used to control visibility of UnblockPermissionsModal.
 */
export const useUnblockPermissionsModal = () =>
  useRecoilState(unblockPermissionsModalState);

export const hiddenIdsState = atom<string[]>({
  key: 'hidden-ids',
  default: [],
});
export const useHiddenIds = () => useRecoilState(hiddenIdsState);

const HiddenParticipantModalState = atom<boolean>({
  key: 'hidden-participant-modal-state',
  default: false,
});
/**
 * Used to control visibility of HiddenParticipantModal.
 */
export const useHiddenParticipantModal = () =>
  useRecoilState(HiddenParticipantModalState);
const leaveWhileRecordingModalState = atom<boolean>({
  key: 'leave-while-recording-modal-state',
  default: false,
});
/**
 * Used to control visibility of LeaveWhileRecordingModal.
 */
export const useLeaveWhileRecordingModal = () =>
  useRecoilState(leaveWhileRecordingModalState);

const muteAllModalState = atom<boolean>({
  key: 'mute-all-modal-state',
  default: false,
});
/**
 * Used to control visibility of MuteAllModal.
 */
export const useMuteAllModal = () => useRecoilState(muteAllModalState);

export type RoomAdmin = {
  userID: string;
  username: string;
  avatar?: string;
};
const adminsListState = atom<RoomAdmin[]>({
  key: 'admins-list',
  default: [],
});

export const useAdminsList = () => useRecoilState(adminsListState);

const isGridHidden = atom<boolean>({
  key: 'is-grid-hidden',
  default: false,
});
/**
 * Returns wether or not this call is in mobile mode and a setter to change it.
 */
export const useIsGridHidden = () => useRecoilState(isGridHidden);
