import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const WarningIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7687 5.09326C11.321 4.15426 12.679 4.15426 13.2314 5.09326L20.7336 17.8471C21.2938 18.7994 20.6072 20 19.5023 20H4.49776C3.39288 20 2.70623 18.7994 3.26642 17.8471L10.7687 5.09326ZM11 10C11 9.44771 11.4477 9 12 9C12.5523 9 13 9.44771 13 10V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V10ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
