import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const ScrollToTableIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path d="M3.5 5H11.5" stroke={color} strokeLinecap="round" />
    <path d="M3.5 7H9.5" stroke={color} strokeLinecap="round" />
    <line
      x1="3.5"
      y1="9"
      x2="9.5"
      y2="9"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M11.5 7L11.5 12.5M11.5 12.5L13 11M11.5 12.5L10 11"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <style jsx>{`
      svg {
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
