import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const ChevronDownIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.146 8.416c.23.288.65.335.937.105l1.667-1.334.062-.056a.667.667 0 0 0-.895-.985L9.25 7.48l-.062.057a.667.667 0 0 0-.042.88ZM7.652 9.902a.667.667 0 0 0 .764-1.09L5.084 6.147l-.068-.048a.667.667 0 0 0-.765 1.09l3.332 2.666.069.048Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
