import classnames from 'classnames';
import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
  variant: 'enter' | 'leave';
}

export const PictureInPictureIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  variant,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M14 5.5H6C5.17157 5.5 4.5 6.17157 4.5 7V13C4.5 13.8284 5.17157 14.5 6 14.5H14C14.8284 14.5 15.5 13.8284 15.5 13V7C15.5 6.17157 14.8284 5.5 14 5.5Z"
      fill="transparent"
      stroke={color}
    />
    <path
      d="M6.85355 7.14645C6.65829 6.95118 6.34171 6.95118 6.14645 7.14645C5.95118 7.34171 5.95118 7.65829 6.14645 7.85355L6.85355 7.14645ZM9.5 10.5V11C9.77614 11 10 10.7761 10 10.5H9.5ZM10 8C10 7.72386 9.77614 7.5 9.5 7.5C9.22386 7.5 9 7.72386 9 8H10ZM7 10C6.72386 10 6.5 10.2239 6.5 10.5C6.5 10.7761 6.72386 11 7 11V10ZM6.14645 7.85355L9.14645 10.8536L9.85355 10.1464L6.85355 7.14645L6.14645 7.85355ZM10 10.5V8H9V10.5H10ZM9.5 10H7V11H9.5V10Z"
      fill={color}
      className={classnames('arrow', {
        leave: variant === 'leave',
      })}
    />
    <path
      d="M10 13C10 11.8954 10.8954 11 12 11H18C19.1046 11 20 11.8954 20 13V17C20 18.1046 19.1046 19 18 19H12C10.8954 19 10 18.1046 10 17V13Z"
      fill={color}
    />
    <style jsx>{`
      svg {
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
      .arrow {
        transform-origin: 8px 9px;
        transition: transform 250ms ease;
      }
      .arrow.leave {
        transform: scale(-1, -1);
      }
    `}</style>
  </svg>
);
