import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const LockIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <rect x="4" y="7" width="8" height="6" rx="1.33333" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3C6.34315 3 5 4.34315 5 6V10H11V6C11 4.34315 9.65685 3 8 3ZM8 4C6.89543 4 6 4.89543 6 6V8H10V6C10 4.89543 9.10457 4 8 4Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
