import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {}

export const Form: React.FC<Props> = ({
  children,
  className,
  onSubmit,
  ...props
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    onSubmit?.(ev);
  };
  const handleSubmitButtonClick = (ev: MouseEvent) => {
    const target: HTMLButtonElement = ev.target as HTMLButtonElement;
    if (
      target?.type === 'submit' &&
      (formRef.current.contains(target) ||
        target?.getAttribute('form') === formRef.current?.id)
    ) {
      setHasSubmitted(true);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleSubmitButtonClick);
    return () => {
      document.removeEventListener('click', handleSubmitButtonClick);
    };
  }, []);
  return (
    <form
      ref={formRef}
      className={classnames(className, {
        submitted: hasSubmitted,
      })}
      onSubmit={handleSubmit}
      {...props}
    >
      {children}
    </form>
  );
};
