import classnames from 'classnames';
import React from 'react';

import { Stack } from '../Stack';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  noDivider?: boolean;
  noPadding?: boolean;
}

export const CardList: React.FC<Props> = ({
  children,
  noDivider = false,
  ...props
}) => (
  <div
    className={classnames('card-list', {
      noDivider,
    })}
    {...props}
  >
    <Stack gap={0}>
      {React.Children.map(children, (child: JSX.Element) =>
        React.cloneElement(child, {
          ...child.props,
          className: classnames('card-list-item', child.props?.className),
        })
      )}
    </Stack>
    <style jsx>{`
      .card-list {
        box-shadow: 1px 1px 0 var(--card-border);
        flex-basis: calc(100% / var(--card-columns));
        flex-grow: 1;
        flex-shrink: 0;
        max-width: 100%;
        min-width: calc(100% / var(--card-columns));
        padding: 8px 0;
      }
      .card-list.noPadding {
        padding: 0;
      }
      .card-list :global(.card-list-item) {
        padding: 12px var(--card-spacing);
      }
      @media (pointer: fine) {
        .card-list :global(.card-list-item) {
          padding: 8px var(--card-spacing);
        }
      }
    `}</style>
  </div>
);
