import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const SpeakerViewIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M5.5 8.5C5.5 7.94772 5.94772 7.5 6.5 7.5H17.5C18.0523 7.5 18.5 7.94772 18.5 8.5V15.5C18.5 16.0523 18.0523 16.5 17.5 16.5H6.5C5.94772 16.5 5.5 16.0523 5.5 15.5V8.5Z"
      stroke={color}
    />
    <line x1="14.5" y1="8" x2="14.5" y2="16" stroke={color} />
    <line x1="15" y1="10.5" x2="18" y2="10.5" stroke={color} />
    <line x1="15" y1="13.5" x2="18" y2="13.5" stroke={color} />
    <style jsx>{`
      svg {
        fill: transparent;
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
