import DailyIframe, {
  DailyCall,
  DailyCallOptions,
  DailyEvent,
  DailyEventObjectAccessState,
  DailyEventObjectLangUpdated,
  DailyRoomInfo,
} from '@daily-co/daily-js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type CallMode = 'direct-link' | 'embedded';
export type CallState =
  | 'awaiting-args'
  | 'ready'
  | 'lobby'
  | 'knocking-cancelled'
  | 'knocking-denied'
  | 'joining'
  | 'joined'
  | 'redirecting'
  | 'ended'
  | 'error'
  | 'expired'
  | 'full'
  | 'nbf'
  | 'not-allowed'
  | 'not-found'
  | 'not-secure'
  | 'removed-from-call'
  | 'left';

interface Props {
  customHost?: string;
  domain: string;
  room: string;
  token?: string;
  isEmbedded: boolean;
  bypassRegionDetection: boolean;
  roomsCheckOrigin?: string;
  apiHost?: string;
  musicMode?: boolean;
}

/**
 * This hook sets up the local call state machine and keeps track of the application state.
 * @param domain – The domain name.
 * @param room – The room name.
 * @param token - A meeting token for private meeting access.
 * @param customHost – Optional custom host to connect to.
 * @param isEmbedded - Whether we're running in a daily-js embedded iframe.
 */
export const useCallMachine = ({
  apiHost,
  bypassRegionDetection,
  customHost,
  domain,
  isEmbedded,
  musicMode,
  room,
  roomsCheckOrigin,
  token,
}: Props) => {
  const { i18n } = useTranslation();
  const [daily, setDaily] = useState<DailyCall>(null);
  const [state, setState] = useState<CallState>(
    isEmbedded ? 'awaiting-args' : 'ready'
  );
  const [closeOnLeave, setCloseOnLeave] = useState<boolean>(false);
  const [redirectOnLeave, setRedirectOnLeave] = useState<boolean>(false);
  const wasDenied = useRef<boolean>(false);
  const wasKnocking = useRef<boolean>(false);
  const [roomInfo, setRoomInfo] = useState<DailyRoomInfo>(null);
  const [callArgs, setCallArgs] = useState<DailyCallOptions>({});

  const url = useMemo(() => {
    if (domain && room) {
      let roomUrl = `https://${
        customHost ? customHost : `${domain}.daily.co`
      }/${room}`;
      const params = new URLSearchParams();
      if (customHost) {
        params.append('cdmn', domain);
      }
      if (bypassRegionDetection) {
        params.append('bypassRegionDetection', 'true');
      }
      if (roomsCheckOrigin) {
        params.append('roomsCheckOrigin', roomsCheckOrigin);
      }
      if (apiHost) {
        params.append('apiHost', apiHost);
      }
      roomUrl = `${roomUrl}?${params.toString()}`;
      return roomUrl;
    }
    return null;
  }, [
    domain,
    room,
    customHost,
    bypassRegionDetection,
    roomsCheckOrigin,
    apiHost,
  ]);

  const disableAudio = useMemo(
    () => new URLSearchParams(window.location.search).get('audio') === 'false',
    []
  );

  /**
   * Helper method to determine wether we want to show the prejoin UX.
   * @param co
   */
  const prejoinUIEnabled = async (co: DailyCall) => {
    const room = (await co.room()) as DailyRoomInfo;
    const { access } = co.accessState();

    // Prejoin config priorities: Token > Room > Domain
    const prejoinEnabled = Boolean(
      room?.tokenConfig?.enable_prejoin_ui ??
        room?.config?.enable_prejoin_ui ??
        room?.domainConfig?.enable_prejoin_ui
    );
    const knockingEnabled = !!room?.config?.enable_knocking;

    return (
      prejoinEnabled ||
      (access !== 'unknown' && access?.level === 'lobby' && knockingEnabled)
    );
  };

  /**
   * Joins the call and tries to init with previously stored devices.
   * @param co – The DailyCall object.
   */
  const join = useCallback(
    async (co: DailyCall) => {
      setState('joining');
      const room = (await co.room()) as DailyRoomInfo;

      // Force mute clients when joining a call with experimental_optimize_large_calls enabled.
      if (room?.config?.experimental_optimize_large_calls) {
        co.setLocalAudio(false);
      }

      await co.join({
        subscribeToTracksAutomatically: false,
        token,
        url,
      });
      setState('joined');
    },
    [token, url]
  );

  /**
   * Preauthenticates, so we know about the user's access state and the room's config.
   * Puts the machine into the next state, based on access state and room config.
   * @param co – The DailyCall object.
   */
  const preAuth = useCallback(
    async (co: DailyCall) => {
      const { access } = await co.preAuth({
        subscribeToTracksAutomatically: false,
        token,
        url,
      });
      const room = (await co.room()) as DailyRoomInfo;
      const { lang } = await co.getDailyLang();
      if (
        room?.domainConfig &&
        room.domainConfig['attach_callobject_to_window']
      ) {
        window['callObject'] = co;
      }
      i18n.changeLanguage(lang);

      /**
       * Private room and no `token` was passed.
       */
      if (access === 'unknown' || access?.level === 'none') {
        return;
      }

      /**
       * Either `enable_knocking_ui` or `enable_prejoin_ui` is set to `true`.
       */
      if (access?.level === 'lobby' || (await prejoinUIEnabled(co))) {
        setState('lobby');
        return;
      }

      /**
       * Public room or private room with passed `token` and `enable_prejoin_ui` is `false`.
       */
      join(co);
    },
    [i18n, join, token, url]
  );

  /**
   * Sets arguments to pass to createCallObject(), and update state to indicate
   * we're ready to start.
   */
  const initializeCallArgs = useCallback(
    (args: DailyCallOptions) => {
      if (state !== 'awaiting-args') return;
      setCallArgs(args);
      setState('ready');
    },
    [state]
  );

  const leave = useCallback(async () => {
    if (!daily) return;

    const accessState = await daily.accessState();
    wasKnocking.current = 'awaitingAccess' in accessState;
    // If we're in the error state, we've already "left", so just clean up
    if (state === 'error') {
      daily.destroy();
    } else {
      daily.leave();
      if (closeOnLeave) {
        daily.destroy();
        // Set meeting to ended state, in case window can't be closed
        setState('ended');
        window.close();
      }
    }
  }, [closeOnLeave, daily, state]);

  /**
   * Set up the call object and preauthenticate.
   */
  useEffect(() => {
    if (daily || !url || state !== 'ready') return;

    if (
      location.protocol !== 'https:' &&
      // We want to still allow local development.
      !['localhost'].includes(location.hostname)
    ) {
      setState('not-secure');
      return;
    }

    const args: DailyCallOptions = {
      ...callArgs,
      url,
      dailyConfig: {
        ...callArgs.dailyConfig,
        experimentalChromeVideoMuteLightOff: true,
        useDevicePreferenceCookies: true,
        // @ts-ignore
        callMode: isEmbedded ? 'prebuilt-embed' : 'prebuilt-direct',
        musicMode,
        ...(musicMode
          ? {
              userMediaAudioConstraints: {
                channelCount: 2,
                autoGainControl: false,
                echoCancellation: false,
                noiseSuppression: false,
                sampleRate: 48000,
                sampleSize: 16,
              },
            }
          : {}),
      },
    };
    if (disableAudio) {
      // @ts-ignore
      args.audioSource = false;
    }
    const co = DailyIframe.createCallObject(args);
    setDaily(co);
    preAuth(co);
  }, [
    callArgs,
    daily,
    disableAudio,
    isEmbedded,
    musicMode,
    preAuth,
    url,
    state,
  ]);

  /**
   * Listen for access state updates.
   */
  const handleAccessStateUpdated = useCallback(
    async ({ access }: DailyEventObjectAccessState) => {
      /**
       * Ignore initial access-state-updated event.
       */
      const ignoreStates: CallState[] = ['ended', 'awaiting-args', 'ready'];
      if (ignoreStates.includes(state)) return;

      if (access === 'unknown' || access?.level === 'none') {
        setState('not-allowed');
        return;
      }

      const meetingState = daily.meetingState();

      if (access?.level === 'lobby' && meetingState === 'joined-meeting') {
        // Already joined, not need to call join(daily) again.
        return;
      }

      /**
       * 'full' access, we can now join the meeting.
       */
      join(daily);
    },
    [daily, join, state]
  );
  useEffect(() => {
    if (!daily) return;

    daily.on('access-state-updated', handleAccessStateUpdated);
    return () => {
      daily.off('access-state-updated', handleAccessStateUpdated);
    };
  }, [daily, handleAccessStateUpdated]);

  /**
   * Set up listeners for meeting state changes
   */
  useEffect(() => {
    if (!daily) return;

    const events: DailyEvent[] = [
      'joined-meeting',
      'joining-meeting',
      'left-meeting',
      'error',
    ];

    const handleMeetingState = async (ev) => {
      const { access } = daily.accessState();
      switch (ev.action) {
        /**
         * Don't transition to 'joining' or 'joined' UI as long as access is not 'full'.
         * This means a request to join a private room is not granted, yet.
         * Technically in requesting for access, the participant is already known
         * to the room, but not joined, yet.
         */
        case 'joining-meeting':
          if (
            access === 'unknown' ||
            access.level === 'none' ||
            access.level === 'lobby'
          )
            return;
          setState('joining');
          break;
        case 'joined-meeting':
          if (
            access === 'unknown' ||
            access.level === 'none' ||
            access.level === 'lobby'
          )
            return;
          setRoomInfo((await daily.room()) as DailyRoomInfo);
          setState('joined');
          break;
        case 'left-meeting':
          daily.destroy();
          if (wasKnocking.current) {
            setState('knocking-cancelled');
            break;
          }
          if (wasDenied.current) {
            setState('knocking-denied');
            break;
          }
          if (!redirectOnLeave) {
            setState('left');
            break;
          }
          setState('redirecting');
          break;
        case 'error':
          switch (ev?.error?.type) {
            case 'nbf-room':
            case 'nbf-token':
              daily.destroy();
              setState('nbf');
              break;
            case 'exp-room':
            case 'exp-token':
              daily.destroy();
              setState('expired');
              break;
            case 'ejected':
              daily.destroy();
              setState('removed-from-call');
              break;
            default:
              switch (ev?.errorMsg) {
                case 'Join request rejected':
                  /**
                   * Join request to a private room was denied. We can end here.
                   */
                  wasKnocking.current = false;
                  wasDenied.current = true;
                  daily.leave();
                  break;
                case 'Meeting has ended':
                  /**
                   * Meeting has ended or participant was removed by an owner.
                   */
                  daily.destroy();
                  setState('ended');
                  break;
                case 'Meeting is full':
                  daily.destroy();
                  setState('full');
                  break;
                case "The meeting you're trying to join does not exist.":
                  daily.destroy();
                  setState('not-found');
                  break;
                case 'You are not allowed to join this meeting':
                  daily.destroy();
                  setState('not-allowed');
                  break;
                default:
                  daily.destroy();
                  setState('error');
                  break;
              }
              break;
          }
          break;
      }
    };

    // Listen for changes in state
    for (const event of events) {
      daily.on(event, handleMeetingState);
    }

    // Stop listening for changes in state
    return () => {
      for (const event of events) {
        daily.off(event, handleMeetingState);
      }
    };
  }, [daily, state, domain, room, redirectOnLeave]);

  /**
   * Listen for language changes.
   */
  useEffect(() => {
    if (!daily) return;

    const handleLangUpdated = (event: DailyEventObjectLangUpdated) => {
      event.lang && i18n.changeLanguage(event.lang);
    };

    daily.on('lang-updated', handleLangUpdated);

    return () => {
      daily.off('lang-updated', handleLangUpdated);
    };
  }, [daily, i18n]);

  useEffect(() => {
    const getURLWithRecentCallParam = (url: string) => {
      const [base, hash] = url.split('#', 2);
      return `${base + (base.includes('?') ? '&' : '?')}recent-call=${domain}/${
        roomInfo?.name
      }${hash ? `#${hash}` : ''}`;
    };

    const getRedirectURL = () => {
      if (roomInfo?.tokenConfig?.redirect_on_meeting_exit) {
        return getURLWithRecentCallParam(
          roomInfo?.tokenConfig?.redirect_on_meeting_exit
        );
      }
      if (roomInfo?.domainConfig?.redirect_on_meeting_exit) {
        return getURLWithRecentCallParam(
          roomInfo?.domainConfig?.redirect_on_meeting_exit
        );
      }
    };

    switch (state) {
      case 'joined':
        if (roomInfo?.tokenConfig?.close_tab_on_exit) {
          setRedirectOnLeave(false);
          setCloseOnLeave(true);
        } else if (
          roomInfo?.tokenConfig?.redirect_on_meeting_exit ||
          roomInfo?.domainConfig?.redirect_on_meeting_exit
        ) {
          setRedirectOnLeave(true);
        }
        break;
      case 'redirecting': {
        if (isEmbedded) {
          // Redirect to...nowhere!
          return;
        }
        window.location.href = getRedirectURL();
        break;
      }
    }
  }, [daily, domain, isEmbedded, roomInfo, state]);

  const mode: CallMode = isEmbedded ? 'embedded' : 'direct-link';

  return {
    daily,
    disableAudio,
    leave,
    mode,
    setRedirectOnLeave,
    state,
    initializeCallArgs,
  };
};
