import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const MetricsIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg
    data-testid="MetricsIcon"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 11L6 6L10 10L14 5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="6" cy="6" r="1.5" fill={color} />
    <circle cx="10" cy="10" r="1.5" fill={color} />
    <circle cx="14" cy="5" r="1.5" fill={color} />
    <circle cx="2" cy="11" r="1.5" fill={color} />
    <style jsx>{`
      svg {
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
