// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  id: string;
  muted?: boolean;
  transition?: boolean;
  size?: number;
}

export const CamIcon: React.FC<Props> = ({
  color = 'currentColor',
  id,
  muted = false,
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        muted
          ? 'M2.707 19.293a1 1 0 1 0 1.414 1.414l1.89-1.89h8.034a2.545 2.545 0 0 0 2.546-2.545v-1.92l3.828 2.734a1 1 0 0 0 1.58-.814V8.545a1 1 0 0 0-1.58-.814l-3.828 2.735v-1.92c0-.089-.005-.176-.013-.261l-.004-.031 3.103-3.104a1 1 0 0 0-1.414-1.414L2.707 19.293Zm11.884-9.056-6.58 6.58h6.034a.545.545 0 0 0 .546-.545V10.238ZM11.642 8H5.545A.545.545 0 0 0 5 8.545V14.642l-1.977 1.977A2.57 2.57 0 0 1 3 16.273V8.545A2.545 2.545 0 0 1 5.545 6h8.097l-2 2ZM20 14.33l-2.689-1.921L20 10.489v3.84Z'
          : 'M4 10.89a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm4.795-3a1 1 0 0 1 1-1h4.25a2.545 2.545 0 0 1 2.546 2.546v1.92l3.828-2.734A1 1 0 0 1 22 9.436v7.727a1 1 0 0 1-1.581.814l-3.828-2.734v1.92a2.545 2.545 0 0 1-2.546 2.546h-8.5A2.545 2.545 0 0 1 3 17.163v-3.772a1 1 0 1 1 2 0v3.772c0 .302.244.546.545.546h8.5a.545.545 0 0 0 .546-.546V9.436a.545.545 0 0 0-.545-.545h-4.25a1 1 0 0 1-1-1Zm8.516 5.41L20 15.22v-3.84l-2.689 1.92Z'
      }
    />

    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);

// export const CamIcon: React.FC<Props> = ({
//   color = 'currentColor',
//   id,
//   muted = false,
//   transition = true,
//   size = 24,
//   ...props
// }) => {
//   const variants: Variants = {
//     muted: {
//       scale: 1,
//       transition: { duration: transition ? 0.24 : 0.1 },
//     },
//     unmuted: {
//       scale: 0,
//       transition: { duration: 0.1 },
//     },
//   };
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
//       {muted && (
//         <mask id={`camIconMask${id}`}>
//           <rect x="0" y="0" width="24" height="24" fill="white" />
//           <motion.path
//             animate="muted"
//             variants={variants}
//             d="M2.87715 19L18.8772 3"
//             stroke="black"
//             strokeWidth="1.5"
//             strokeLinecap="round"
//           />
//         </mask>
//       )}
//       <g mask={muted ? `url(#camIconMask${id})` : undefined}>
//         <path d="M5 7.5C3.89543 7.5 3 8.39543 3 9.5V14.5C3 15.6046 3.89543 16.5 5 16.5H13C14.1046 16.5 15 15.6046 15 14.5V9.5C15 8.39543 14.1046 7.5 13 7.5H5Z" />
//         <path d="M16.5 10.9491C16.5 10.6634 16.6221 10.3914 16.8356 10.2017L19.3356 7.97943C19.9805 7.40618 21 7.86399 21 8.72684V15.2732C21 16.136 19.9805 16.5938 19.3356 16.0206L16.8356 13.7983C16.6221 13.6086 16.5 13.3366 16.5 13.0509V10.9491Z" />
//       </g>
//       <motion.path
//         animate={muted ? 'muted' : 'unmuted'}
//         variants={variants}
//         d="M4 20L20 4"
//         stroke={color}
//         strokeLinecap="round"
//         strokeWidth="1.5"
//       />
//       <style jsx>{`
//         svg {
//           fill: ${color};
//           height: ${size}px;
//           width: ${size}px;
//           display: block;
//         }
//       `}</style>
//     </svg>
//   );
// };
