import { DailyCallOptions } from '@daily-co/daily-js';
import { DailyProvider } from '@daily-co/daily-react-hooks';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CallMode, CallState, useCallMachine } from './useCallMachine';

interface Props {
  customHost?: string;
  domain: string;
  room: string;
  token?: string;
  isEmbedded: boolean;
  bypassRegionDetection: boolean;
  roomsCheckOrigin?: string;
  apiHost?: string;
  musicMode?: boolean;
}

export type VideoQuality = 'auto' | 'high' | 'low' | 'bandwidth-saver';

interface ContextValue {
  attendeeUIInteraction: boolean;
  disableAudio: boolean;
  enableCallButtons: boolean;
  enableJoinSound: boolean;
  initializeCallArgs(options: DailyCallOptions);
  isFullscreen: boolean;
  leaveCall(): void;
  mode: CallMode;
  preJoinNonAuthorized: boolean;
  reloadCall(): void;
  setAttendeeUIInteraction(b: boolean): void;
  setEnableJoinSound(b: boolean): void;
  setIsFullscreen(b: boolean): void;
  setRedirectOnLeaveCall(redirect: boolean): void;
  setShowFullscreenButton(b: boolean): void;
  setShowLeaveButton(b: boolean): void;
  setShowNames(b: boolean): void;
  setShowParticipantsBar(b: boolean): void;
  setVideoQuality(q: VideoQuality): void;
  showFullscreenButton: boolean;
  showLeaveButton: boolean;
  showNames: boolean;
  showParticipantsBar: boolean;
  state: CallState;
  videoQuality: VideoQuality;
}

export const CallContext = createContext<ContextValue>(null);

export const CallProvider: React.FC<Props> = ({
  apiHost = null,
  bypassRegionDetection = false,
  children,
  customHost,
  domain,
  isEmbedded,
  musicMode = false,
  room,
  roomsCheckOrigin = null,
  token = '',
}) => {
  const [attendeeUIInteraction, setAttendeeUIInteraction] = useState(false);
  const [enableJoinSound, setEnableJoinSound] = useState(true);
  const [preJoinNonAuthorized, setPreJoinNonAuthorized] = useState(false);
  const [showFullscreenButton, setShowFullscreenButton] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showNames, setShowNames] = useState(true);
  const [showParticipantsBar, setShowParticipantsBar] = useState(true);
  const [showLeaveButton, setShowLeaveButton] = useState(false);
  const [videoQuality, setVideoQuality] = useState<VideoQuality>('auto');

  const {
    daily,
    disableAudio,
    initializeCallArgs,
    leave,
    mode,
    setRedirectOnLeave,
    state,
  } = useCallMachine({
    domain,
    room,
    token,
    customHost,
    isEmbedded,
    bypassRegionDetection,
    roomsCheckOrigin,
    apiHost,
    musicMode,
  });

  /**
   * Only enable the call buttons (camera toggle, leave call, etc.) if we're joined
   * or if we've errored out.
   *
   * !!!
   * IMPORTANT: calling callObject.destroy() *before* we get the "joined-meeting"
   * can result in unexpected behavior. Disabling the leave call button
   * until then avoids this scenario.
   * !!!
   */
  const enableCallButtons = useMemo(
    () => ['joined', 'error'].includes(state),
    [state]
  );

  const reloadCall = useCallback(() => {
    const url = new URL(window.location.href);
    if (!!token) {
      url.searchParams.set('t', token);
    }
    location.href = url.toString();
  }, [token]);

  useEffect(() => {
    if (!daily) return;
    const { access } = daily.accessState();
    if (access === 'unknown') return;
    const requiresPermission = access?.level === 'lobby';
    setPreJoinNonAuthorized(requiresPermission && !token);
  }, [state, daily, token]);

  useEffect(() => {
    setShowLeaveButton(mode === 'direct-link');
  }, [mode]);

  return (
    <CallContext.Provider
      value={{
        attendeeUIInteraction,
        disableAudio,
        enableCallButtons,
        enableJoinSound,
        initializeCallArgs,
        isFullscreen,
        leaveCall: leave,
        mode,
        preJoinNonAuthorized,
        reloadCall,
        setAttendeeUIInteraction,
        setEnableJoinSound,
        setIsFullscreen,
        setRedirectOnLeaveCall: setRedirectOnLeave,
        setShowFullscreenButton,
        setShowLeaveButton,
        setShowNames,
        setShowParticipantsBar,
        setVideoQuality,
        showFullscreenButton,
        showLeaveButton,
        showNames,
        showParticipantsBar,
        state,
        videoQuality,
      }}
    >
      <DailyProvider callObject={daily}>{children}</DailyProvider>
    </CallContext.Provider>
  );
};

export const useCallState = () => useContext(CallContext);
