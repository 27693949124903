import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const ShowUserIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1486 4.74457C11.1486 6.26036 9.91979 7.48914 8.404 7.48914C6.88822 7.48914 5.65943 6.26036 5.65943 4.74457C5.65943 3.22879 6.88822 2 8.404 2C9.91979 2 11.1486 3.22879 11.1486 4.74457ZM12.316 10.411L12.316 10.411C12.7916 10.2926 13.275 10.2338 13.7664 10.2338C14.7321 10.2338 15.658 10.4584 16.5548 10.912C17.0057 11.1401 17.556 10.9595 17.7841 10.5086C18.0121 10.0577 17.8315 9.50735 17.3806 9.2793C16.2299 8.69726 15.0215 8.40407 13.7664 8.40407C10.7439 8.40407 8.0467 10.0898 5.70379 13.3604C5.47561 13.679 5.47561 14.1074 5.70379 14.426C8.0467 17.6966 10.7439 19.3824 13.7664 19.3824C16.7889 19.3824 19.486 17.6966 21.829 14.426C22.0784 14.0777 22.0528 13.603 21.7674 13.2836C21.2786 12.7367 20.8726 12.29 20.5487 11.9428L20.2056 11.5802L20.0167 11.3872C19.66 11.0293 19.0808 11.0283 18.7229 11.3849C18.365 11.7416 18.364 12.3208 18.7206 12.6787C18.7463 12.7045 18.7733 12.7319 18.8018 12.761L19.2108 13.1909L19.9023 13.9454L19.729 14.1651C17.8771 16.448 15.8966 17.5526 13.7664 17.5526L13.5303 17.5481C11.4111 17.4662 9.44088 16.2768 7.59855 13.9066L7.58834 13.8923L7.80378 13.6213C8.56326 12.6851 9.34437 11.947 10.1486 11.402L10.1486 11.402C10.853 10.9247 11.5752 10.5955 12.316 10.411ZM6.82161 9.48783C4.06196 10.0817 2 12.1321 2 13.8931C2 14.3984 2.4096 14.808 2.91486 14.808C3.34476 14.808 3.70541 14.5115 3.80342 14.1118C3.75288 13.4989 3.91531 12.8723 4.29071 12.3482C5.07524 11.253 5.919 10.2925 6.82161 9.48783ZM16.5109 13.8933C16.5109 15.409 15.2821 16.6378 13.7663 16.6378C12.2505 16.6378 11.0217 15.409 11.0217 13.8933C11.0217 12.3775 12.2505 11.1487 13.7663 11.1487C15.2821 11.1487 16.5109 12.3775 16.5109 13.8933Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
