import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const HelpIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13ZM8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.38337 9.01386H8.45479V8.81397C8.50277 8.39019 8.91055 8.06237 9.29434 7.69456C9.63816 7.35874 9.94999 6.99094 9.94999 6.43923C9.94999 5.59168 9.35031 5 8.17494 5C7.03955 5 6.24797 5.65565 6.2 6.76706H7.3114C7.36737 6.26333 7.66322 5.9435 8.10298 5.9435C8.52675 5.9435 8.71865 6.16738 8.71865 6.53518C8.71865 6.85501 8.46279 7.11087 8.17494 7.39072C7.79914 7.75853 7.38337 8.15032 7.38337 8.77399V9.01386ZM7.95409 11C8.36393 11 8.7 10.6705 8.7 10.2543C8.7 9.82948 8.36393 9.5 7.95409 9.5C7.53606 9.5 7.2 9.82948 7.2 10.2543C7.2 10.6705 7.53606 11 7.95409 11Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
