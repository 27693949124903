import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const EllipsisIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33334 7.99984C5.33334 7.26346 4.73638 6.6665 4.00001 6.6665C3.26363 6.6665 2.66667 7.26346 2.66667 7.99984C2.66667 8.73622 3.26363 9.33317 4.00001 9.33317C4.73638 9.33317 5.33334 8.73622 5.33334 7.99984ZM9.33333 7.99992C9.33333 7.26354 8.73638 6.66659 8 6.66659C7.26362 6.66659 6.66667 7.26354 6.66667 7.99992C6.66667 8.7363 7.26362 9.33325 8 9.33325C8.73638 9.33325 9.33333 8.7363 9.33333 7.99992ZM12 6.66659C12.7364 6.66659 13.3333 7.26354 13.3333 7.99992C13.3333 8.7363 12.7364 9.33325 12 9.33325C11.2636 9.33325 10.6667 8.7363 10.6667 7.99992C10.6667 7.26354 11.2636 6.66659 12 6.66659Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
