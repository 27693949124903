import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
  variant?: 'default' | 'thick';
}

export const CaretLeftIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  variant = 'thick',
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <line
      x1="9"
      y1="5"
      x2="6"
      y2="8"
      stroke={color}
      strokeLinecap="round"
      strokeWidth={variant === 'thick' ? 2 : 1}
    />
    <line
      x1="6"
      y1="8"
      x2="9"
      y2="11"
      stroke={color}
      strokeLinecap="round"
      strokeWidth={variant === 'thick' ? 2 : 1}
    />
    <style jsx>{`
      svg {
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
