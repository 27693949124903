import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const ParticipantsIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.115 8.934a1.967 1.967 0 1 0 0-3.934 1.967 1.967 0 0 0 0 3.934Zm12.787 9.836c.543 0 .983-.44.983-.983 0-2.241-3.106-4.918-6.885-4.918-3.78 0-6.885 2.677-6.885 4.918a.984.984 0 0 0 1.96.115l.007-.115c0-.287.433-1.025 1.134-1.63.959-.826 2.244-1.32 3.784-1.32 1.54 0 2.825.494 3.784 1.32.701.605 1.134 1.343 1.134 1.63 0 .543.44.983.984.983Zm2.95-11.803a1.967 1.967 0 1 1-3.934 0 1.967 1.967 0 0 1 3.934 0Zm-1.967 2.754c-1.028 0-1.988.25-2.791.645l-.051.025-.03.047c-.21.338-.46.646-.745.92l-.23.22.3.107c.344.123.68.264 1.005.42l.102.05.094-.064c.617-.417 1.408-.665 2.346-.665 1.06 0 1.933.317 2.58.837.236.19.426.4.555.583.065.092.113.173.144.24a.59.59 0 0 1 .03.08c.007.02.007.029.007.03 0 .484.416.853.9.853.483 0 .899-.37.899-.853 0-.84-.614-1.71-1.53-2.36-.924-.656-2.193-1.115-3.585-1.115Zm-13.77 0c-1.392 0-2.661.46-3.585 1.115-.916.65-1.53 1.52-1.53 2.36 0 .484.416.853.9.853.45 0 .839-.318.893-.752l.006-.089v-.012c0-.001 0-.01.006-.03a.582.582 0 0 1 .031-.08c.031-.067.079-.148.144-.24.129-.182.319-.393.555-.583.647-.52 1.52-.837 2.58-.837.938 0 1.73.248 2.346.665l.094.063.102-.049c.325-.156.66-.297 1.005-.42l.3-.107-.23-.22a4.746 4.746 0 0 1-.745-.92l-.03-.047-.05-.025a6.368 6.368 0 0 0-2.792-.645Zm9.836-1.77a2.95 2.95 0 1 1-5.902 0 2.95 2.95 0 0 1 5.902 0Z"
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
