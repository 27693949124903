import React from 'react';

import { useTheme } from '../../contexts/Theme';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const TerminalIcon: React.FC<Props> = ({
  fill = useTheme().colors.system.grey,
  stroke = useTheme().colors.system.greyDark,
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <rect x="0.5" y="2.5" width="15" height="11" rx="3.5" />
    <path d="M10 10L12 8L10 6" strokeLinecap="round" strokeLinejoin="round" />
    <style jsx>{`
      svg {
        fill: ${fill};
        height: ${size}px;
        stroke: ${stroke};
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
