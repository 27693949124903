import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const UnpinIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.293 18.879a1 1 0 1 0 1.414 1.414L9 16h7.257c1.105 0 2.033-.922 1.658-1.96-.494-1.374-1.494-2.52-2.786-3.237V9.87l5.135-5.134a1 1 0 0 0-1.415-1.415L3.293 18.88ZM14.173 4c.326 0 .639.083.902.226L8.87 10.43V6.645a4.014 4.014 0 0 1-.535-.733C7.79 4.952 8.725 4 9.829 4h4.343ZM11 17h2v2.062L11.007 21a.934.934 0 0 1-.007-.112V17Z"
      />
      <style jsx>{`
        svg {
          fill: ${color};
          height: ${size}px;
          width: ${size}px;
          display: block;
        }
      `}</style>
    </svg>
  </>
);
