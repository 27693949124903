import classnames from 'classnames';
import React, { createContext, useContext } from 'react';

import { useTheme } from '../../contexts/Theme/useTheme';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { LoadingSpinner } from '../LoadingSpinner';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  appearance?: 'default' | 'grey' | 'warning';
  columns?: number | number[];
  loading?: boolean;
  noBorder?: boolean;
  shadow?: 'none' | 'default' | 'strong';
  spacing?: number;
  variant?: 'small' | 'large';
}

const CardContext = createContext({
  columns: 1,
});

export const useCardContext = () => useContext(CardContext);

export const Card: React.FC<Props> = ({
  appearance = 'default',
  className,
  children,
  columns = 1,
  loading = false,
  noBorder = false,
  shadow = 'default',
  spacing = 16,
  variant = 'small',
  ...props
}) => {
  const { mediaQueries } = useTheme();
  const spacingMap = {
    small: 16,
    large: 32,
  };

  const columnsMobile = typeof columns === 'number' ? columns : columns[0];
  const columnsTablet =
    typeof columns === 'number' ? columns : columns?.[1] || columns[0];
  const columnsDesktop =
    typeof columns === 'number'
      ? columns
      : columns?.[2] || columns?.[1] || columns[0];
  const columnsWide =
    typeof columns === 'number'
      ? columns
      : columns?.[3] || columns?.[2] || columns?.[1] || columns[0];

  const isTablet = useMediaQuery(mediaQueries.medium);
  const isDesktop = useMediaQuery(mediaQueries.large);
  const isWide = useMediaQuery(mediaQueries.xlarge);

  const currentColumns = isWide
    ? columnsWide
    : isDesktop
    ? columnsDesktop
    : isTablet
    ? columnsTablet
    : columnsMobile;

  return (
    <div
      className={classnames(
        'card',
        appearance,
        className,
        `shadow-${shadow}`,
        variant,
        {
          noBorder,
        }
      )}
      {...props}
    >
      <CardContext.Provider
        value={{
          columns: currentColumns,
        }}
      >
        {children}
      </CardContext.Provider>
      {loading && (
        <div className="cardLoader">
          <LoadingSpinner size={24} />
        </div>
      )}
      <style jsx>{`
        .card {
          --card-columns: ${currentColumns};
          --card-spacing: ${spacing >= 0 ? spacing : spacingMap[variant]}px;

          border: 1px solid var(--card-border);
          border-radius: 4px;
          display: flex;
          flex-direction: ${currentColumns > 1 ? 'row' : 'column'};
          flex-wrap: ${currentColumns > 1 ? 'wrap' : 'nowrap'};
          height: 100%;
          overflow: hidden;
          position: relative;
          width: 100%;
        }
        .card.default {
          background: var(--card-bg);
        }
        .card.grey {
          background: var(--card-grey-bg);
        }
        .card.warning {
          background: var(--card-warning-bg);
          border-color: var(--card-warning-border);
        }
        .noBorder {
          border: none;
        }
        .shadow-default {
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
            0px 2px 2px rgba(0, 0, 0, 0.04);
        }
        .shadow-strong {
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
        }
        .cardLoader {
          align-items: center;
          background: var(--card-loader-bg);
          border-radius: 4px;
          bottom: 0;
          display: flex;
          justify-content: center;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      `}</style>
    </div>
  );
};
