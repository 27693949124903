import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const SendMessageIcon: React.FC<Props> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2935 4.02011C10.2382 3.08084 9.44669 2.33594 8.4784 2.33594H7.29658H3.59961H2.41779L2.31096 2.33897C1.35653 2.3934 0.599609 3.17233 0.599609 4.12524V5.33594V6.49315V7.70385L0.602696 7.80898C0.657997 8.74825 1.4495 9.49315 2.41779 9.49315H3.02385V10.686L3.02732 10.7517C3.07533 11.2018 3.61217 11.4447 3.99355 11.1632L6.25597 9.49315H7.29658H8.4784L8.58523 9.49011C9.53966 9.43569 10.2966 8.65676 10.2966 7.70385V7.10741L10.2925 7.03785C10.2877 6.99747 10.2789 6.95832 10.2663 6.92081C10.2863 6.78113 10.2966 6.63834 10.2966 6.49315V5.33594C10.2966 5.19074 10.2863 5.04796 10.2663 4.90827C10.286 4.84957 10.2966 4.78684 10.2966 4.72167V4.12524L10.2935 4.02011ZM4.23597 5.91455C4.23597 6.24396 3.96462 6.51099 3.62991 6.51099C3.29519 6.51099 3.02384 6.24396 3.02384 5.91455C3.02384 5.58515 3.29519 5.31812 3.62991 5.31812C3.96462 5.31812 4.23597 5.58515 4.23597 5.91455ZM6.05416 5.91455C6.05416 6.24396 5.78282 6.51099 5.4481 6.51099C5.11338 6.51099 4.84204 6.24396 4.84204 5.91455C4.84204 5.58515 5.11338 5.31812 5.4481 5.31812C5.78282 5.31812 6.05416 5.58515 6.05416 5.91455ZM7.26626 6.51099C7.60098 6.51099 7.87232 6.24396 7.87232 5.91455C7.87232 5.58515 7.60098 5.31812 7.26626 5.31812C6.93154 5.31812 6.6602 5.58515 6.6602 5.91455C6.6602 6.24396 6.93154 6.51099 7.26626 6.51099ZM12.9763 5.91458V4.72171H13.5824L13.6892 4.72475C14.6436 4.77917 15.4006 5.5581 15.4006 6.51101V10.0896L15.3975 10.1948C15.3422 11.134 14.5507 11.8789 13.5824 11.8789H12.9763V13.0718L12.9728 13.1375C12.9248 13.5876 12.388 13.8304 12.0066 13.5489L9.74419 11.8789H7.52177L7.41494 11.8759C7.17597 11.8623 6.94938 11.8032 6.74396 11.7074L8.1276 10.6861H9.94601L10.0241 10.691C10.1273 10.7042 10.2257 10.7434 10.3096 10.8053L11.7642 11.8789V11.2825L11.7683 11.2129C11.8033 10.9163 12.0594 10.6861 12.3703 10.6861H13.5824L13.6531 10.682C13.9545 10.6476 14.1884 10.3955 14.1884 10.0896V6.51101L14.1844 6.44145C14.1493 6.14482 13.8932 5.91458 13.5824 5.91458H12.9763Z"
      fill={color}
    />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
