import { GlobalCSS } from '@daily/shared/components/GlobalCSS';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useResize } from '@daily/shared/hooks/useResize';
import Head from 'next/head';
import { useEffect, useState } from 'react';

import { useCustomTrayButtons, useSidebarView } from '../../contexts/UIState';
import { Meta } from './Meta';

export const Layout: React.FC = ({ children }) => {
  const { colors } = useTheme();
  const [sidebarView] = useSidebarView();
  const [customTrayButtons] = useCustomTrayButtons();

  /**
   * Sometimes, when rotating an iOS device, the viewport can end up being cropped,
   * because the <html>'s top position becomes negative.
   * We're going to `translateY` the app in that case, to neutralize that effect.
   */
  const [pageOffset, setPageOffset] = useState(0);
  useResize(() => {
    const { top } = document.documentElement.getBoundingClientRect();
    setPageOffset(-1 * top);
  }, [sidebarView]);

  /**
   * iOS < 15 layout hack: rotating the device hides/minifies Safari's browser bars.
   * Forcing a repaint re-shows browser bars.
   */
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const handleOrientationChange = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        document.documentElement.style.height = `${window.innerHeight}px`;
        timeout = setTimeout(() => {
          document.documentElement.style.height = '';
        }, 0);
      }, 500);
    };
    window.addEventListener('orientationchange', handleOrientationChange);
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <div
      style={{
        transform: pageOffset ? `translateY(${pageOffset}px)` : null,
      }}
    >
      <Head>
        {Object.entries(customTrayButtons).map(([id, btn]) => (
          // Prefetches icons for customTrayButtons as soon as possible
          <link key={id} rel="prefetch" href={btn.iconPath} />
        ))}
      </Head>
      <GlobalCSS />
      <Meta />
      {children}
      <style global jsx>{`
        :root {
          --zindex-sidebar: 15;
          --zindex-tray: 10;
          --zindex-waitingroom: 5;
        }
        html {
          height: 100%;
          width: 100%;
        }
        body {
          --snackbar-info-bg: ${colors.backgroundAccent};
          --snackbar-info-color: ${colors.baseText};
          --snackbar-success-bg: ${colors.backgroundAccent};
          --snackbar-success-color: ${colors.baseText};

          background-color: transparent;
          // background-color: var(--body-bg-accent);
          height: 100%;
          overflow: hidden;
          width: 100%;
        }
        ::-webkit-scrollbar {
          background: ${colors.background};
          width: 12px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${colors.supportiveText};
          border: 2px solid ${colors.background};
          border-radius: 6px;
        }
      `}</style>
      <style jsx>{`
        div {
          bottom: 0;
          left: 0;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
        }
      `}</style>
    </div>
  );
};
